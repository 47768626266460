<template>
	<section class="section section--larger">
		<div class="container">
			<div class="article">
				<div class="article__image is-relative">
					<img
						:src="
							`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${article.imagine.url}`
						"
						:alt="article.titlu"
					/>
				</div>
				<ul
					class="article__meta is-flex is-justify-content-center py-3"
				>
					<li class="is-flex is-align-items-center mr-3">
						<div class="icon">
							<ion-icon name="calendar-clear-outline"></ion-icon>
						</div>
						<span
							>{{
								moment(article.published_at).format(
									'DD.MM.YYYY'
								)
							}}
						</span>
					</li>
				</ul>
				<h2 class="article__title has-text-centered mb-3">
					{{ article.titlu }}
				</h2>
				<markdown-it-vue
					class="article__description"
					:content="article.descriere_lunga"
				/>
			</div>
		</div>
	</section>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		metaInfo() {
			return {
				title: `Forever Diamond Team - ${this.article.titlu}`,
			};
		},
		created() {
			this.getArticle(this.articleId);
		},
		data() {
			return {};
		},
		computed: {
			...mapState('article', ['article']),
			articleId() {
				let route = this.$route.path;
				return route.slice(route.lastIndexOf('-') + 1);
			},
		},
		methods: {
			...mapActions('article', ['getArticle']),
		},
	};
</script>

<style scoped lang="scss">
	.article {
		&__image {
			padding-top: 56.25%;
			overflow: hidden;
		}
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		&__meta {
			span {
				font-weight: $medium;
			}
		}
	}
</style>
